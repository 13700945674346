import React, { useState } from 'react';
import { Select } from 'antd';

const IconFont = Loader.loadBaseComponent('IconFont');
const Option = Select.Option;

function ControlStateSelect({ value, onChange }) {
  const [status, setStatus] = useState(value || null);
  const handleTypeChange = function(key) {
    setStatus(key);
    onChange && onChange({ status: key });
  };
  return (
    <div style={{ marginRight: '8px' }}>
      <Select
        dropdownClassName="header_filter_select_type_downwrap"
        className="header_filter_type_select"
        style={{ width: 110 }}
        value={status}
        onChange={handleTypeChange}
        defaultValue={status}
        size="small"
        suffixIcon={<IconFont type="icon-S_Arrow_BigDown" />}
      >
        <Option value={null}>全部状态</Option>
        <Option value={-1}>未处理</Option>
        <Option value={0}>未违规</Option>
        <Option value={1}>已违规</Option>
      </Select>
    </div>
  );
}

export default ControlStateSelect;
