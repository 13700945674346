export function changeCode(checkedValues) {
  const arr = [];
  checkedValues.forEach(v => {
    switch (v) {
      case '119100':
        arr.push('102412');
        break;
      case '119102':
        arr.push('102413');
        break;
      case '119103':
        arr.push('102416');
        break;
      case '119104':
        arr.push('102417');
        break;
      case '119105':
        arr.push('102415');
        break;
      case '119106':
        arr.push('102422');
        break;
      case '119107':
        arr.push('102425');
        break;
      case '119108':
        arr.push('102427');
        break;
      case '119109':
        arr.push('102430');
        break;
      case '119111':
        arr.push('102426');
        break;
      case '119112':
        arr.push('102435');
        break;
      case '119113':
        arr.push('102428');
        break;
      case '119114':
        arr.push('102429');
        break;
      case '119116':
        arr.push('102414');
        break;
      case '119122':
        arr.push('102423');
        break;
      case '119123':
        arr.push('102424');
        break;
      case '119128':
        arr.push('102423');
        break;
      case '119129':
        arr.push('102436');
        break;
      case '119199':
        arr.push('102499');
        break;
      default:
        return;
    }
  });
  return checkedValues.concat(arr);
}
