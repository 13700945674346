import React, { Component } from "react";
import "./style/index.less";

const PictureView = Loader.loadBaseComponent("PictureView");
const IconFont = Loader.loadBusinessComponent("IconFont");

class SelectIMGPicture extends Component {
  constructor(props) {
    super(props);
  }
  getSelectResult = async func => {
    const { onClickSelectRect } = this.props;
    const url = await func();
    onClickSelectRect && onClickSelectRect(url);
  };
  render() {
    const { src, rects = [], onClickDefaultRect, lazyLoad } = this.props;
    return (
      <div className="select-img-picture">
        <PictureView
          lazyLoad={lazyLoad}
          imagePath={src}
          size={{ width: 960, height: 540 }}
          key={src}
          showDefaultRect={false}
          showRect={true}
          rects={rects}
          onClickDefaultRect={onClickDefaultRect}
          disabledDrag={true}
        >
          {options => {
            const { getSelectResult, changeSelectStatus, isOpenSelect, isOverSelect } = options;
            return (
              <div className="select-img-prcture-footer">
                <div className="footer-button" onClick={() => changeSelectStatus(!isOpenSelect)}>
                  <IconFont type="icon-S_View_SearchBox" />
                  {isOpenSelect ? "取消" : "手动"}框选
                </div>
                {isOpenSelect && isOverSelect && (
                  <div className="footer-button" onClick={() => this.getSelectResult(getSelectResult)}>
                    <IconFont type="icon-S_Edit_ImgSearch" />
                    搜索截图
                  </div>
                )}
              </div>
            );
          }}
        </PictureView>
      </div>
    );
  }
}

export default SelectIMGPicture;
