/**
 * @author wwj
 * @createTime 2019-1-7
 * @modify tlzj 2019-5-14
 */

import React, { Component } from "react";
import { Button } from "antd";
import "./index.less";
const List = Loader.loadBaseComponent("ReactVirtualized", "SimpleList");
const MapSelectModal = Loader.loadBusinessComponent("ModalSelectMap");
const OrgSelectModal = Loader.loadBusinessComponent("ModalTreeSelectCamera");
const DeviceIcon = Loader.loadBaseComponent("DeviceIcon");
const IconFont = Loader.loadBaseComponent("IconFont");

@Decorator.businessProvider("device")
@Decorator.businessObserver({device:['cameraList']})
class GrapPoint extends Component {
  state = {
    modalShow: false,
    keyMath: Math.random(),
    modalType: false
  };

  componentDidMount() {
    const { type } = this.props;
    if (type === "accessControl") {
      let points = this.props.device.deviceArray.filter(v => Dict.map.mj.value.indexOf(v.deviceType) > -1);
      Promise.all([
        Service.device.countOrganizationDeviceStatus({ deviceTypes: Dict.map.mj.value.split(",").map(item => +item) }), // 计算组织设备下的门禁数目
        Service.device.countPlaceDeviceStatus({ deviceTypes: Dict.map.mj.value.split(",").map(item => +item) }) // 计算场所下的门禁数目
      ]).then(res => {
        // 组织设备树
        let orgDevice = res[0] ? res[0].data || [] : [];
        let orgList = Utils.computTreeList(Shared.computedOrganizationDevice(BaseStore.organization.orgList, orgDevice));
        // 场所设备树
        let placeCamera = res[1] ? res[1].data || [] : [];
        let placeList = Shared.computedPlaceDeviceCount(BaseStore.place.placeList, placeCamera);
        let placeListHandle = placeList.map(v => {
          return {
            ...v,
            placeId: v.id,
            id: v.areaCode,
            name: v.areaName,
            parentId: v.parentCode,
            deviceCount: {
              onlineCount: v.deviceCount ? v.deviceCount.onlineCount : 0,
              count: v.deviceCount ? v.deviceCount.count : 0
            },
            cameraCount: {
              onlineCount: v.cameraCount ? v.cameraCount.onlineCount : 0,
              count: v.cameraCount ? v.cameraCount.count : 0
            }
          };
        });
        this.setState({
          orgTreeData: orgList,
          deviceList: points,
          placeTreeData: Utils.computTreeList(placeListHandle)
        });
      });
    }
  }

  /**确定 */
  handleOk = selectList => {
    const { onChange, name } = this.props;
    onChange && onChange({ [name || "cameraIds"]: selectList });
    this.handleCancel();
  };

  // 清空默认选中选择设备
  clearDevices = () => {
    const { onChange, name } = this.props;
    onChange && onChange({ [name || "cameraIds"]: [] });
  };

  /**取消 */
  handleCancel = () => {
    this.setState({
      modalShow: false,
      keyMath: Math.random(),
      modalType: false
    });
  };

  showDeviceSelect = modalType => {
    this.setState({
      modalShow: true,
      modalType
    });
  };

  // 渲染列表项
  rowRender = ({ key, index, style }) => {
    let v = this.props.selectList[index];
    return (
      <div style={style} key={v.id} title={v.deviceName} className="li">
        <DeviceIcon type={v.deviceType} status={v.deviceStatus} />
        {v.deviceName}
      </div>
    );
  };

  render() {
    const { label = "点位", selectList = [], device, type } = this.props;
    const { deviceList } = this.state;
    let points = device.cameraList.filter(v => v.deviceType !== Dict.map.db.value);
    if (type === "accessControl") {
      points = deviceList || [];
    }
    const { modalType } = this.state;
    return (
      <div className="point-select-wrapper">
        <div className="search-title">
          <IconFont type="icon-S_Bar_Add" />
          {label}：
        </div>
        <div className="search-content">
          <div className="clearfix">
            <Button className="fl" onClick={() => this.showDeviceSelect(1)}>
              <IconFont type="icon-S_Photo_ListTree" />
              列表模式
            </Button>
            <Button className="fr" onClick={() => this.showDeviceSelect(2)}>
              <IconFont type="icon-S_Photo_ListMap" />
              地图模式
            </Button>
          </div>
          {!!selectList.length && (
            <div className="grap-point-content">
              <div className="camera-selected">
                <List data={selectList} rowHeight={24} key={this.state.keyMath} rowRenderer={this.rowRender} />
              </div>
              <div className="clear" onClick={this.clearDevices}>
                <IconFont type="icon-S_Edit_Delete" className="data-repository-icon" />
                清空摄像机
              </div>
            </div>
          )}
        </div>
        {modalType &&
          (modalType === 2 ? (
            <MapSelectModal
              title={"抓拍点位"}
              width={920}
              points={points}
              className="point-select-map"
              key={this.state.modalShow}
              visible={this.state.modalShow}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              selectList={selectList}
              destroyOnClose
              orgTreeData={this.state.orgTreeData}
            />
          ) : (
            <OrgSelectModal
              title="抓拍点位"
              visible={this.state.modalShow}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              selectList={selectList}
              noSoldier={true}
              orgTreeData={this.state.orgTreeData}
              placeTreeData={this.state.placeTreeData}
              deviceList={deviceList}
            />
          ))}
      </div>
    );
  }
}

export default GrapPoint;
