/**
 * @author tlzj
 * @createTime 2019-3-8
 */

import React, { Component } from "react";
import { Button } from "antd";
import "./index.less";
import WifiListSelectModal from "./component/index.js";
const List = Loader.loadBaseComponent("ReactVirtualized", "SimpleList");
const MapSelectModal = Loader.loadBusinessComponent("ModalSelectMap");
const DeviceIcon = Loader.loadBaseComponent("DeviceIcon");
const IconFont = Loader.loadBaseComponent("IconFont");

class GrapPoint extends Component {
  state = {
    modalShow: false,
    keyMath: Math.random(),
    modalType: false,
    resourceData: []
  };

  componentDidMount() {
    Service.wifi
      .queryWifiCaptureDevices({
        limit: 10000,
        offset: 0
      })
      .then(res => {
        this.setState({
          resourceData: res.data.list || []
        });
      });
  }

  /**确定 */
  handleOk = selectList => {
    const { onChange } = this.props;
    onChange && onChange({ cids: selectList });
    this.handleCancel();
  };

  // 清空默认选中选择设备
  clearDevices = () => {
    const { onChange } = this.props;
    onChange && onChange({ cids: [] });
  };

  /**取消 */
  handleCancel = () => {
    this.setState({
      modalShow: false,
      keyMath: Math.random(),
      modalType: false
    });
  };

  showDeviceSelect = modalType => {
    this.setState({
      modalShow: true,
      modalType
    });
  };

  // 渲染列表项
  rowRender = ({ index, style }) => {
    let v = this.props.selectList[index];
    return (
      <div style={style} key={v.id} title={v.deviceName} className="li">
        <DeviceIcon type={v.deviceType} status={v.deviceStatus} />
        {v.deviceName}
      </div>
    );
  };

  render() {
    const { label = "点位", selectList = [] } = this.props;
    let WifiIds = selectList.map(item => item.id);
    const { modalType, resourceData } = this.state;
    return (
      <div className="point-select-wrapper">
        <div className="search-title">
          <IconFont type="icon-S_Bar_Add" />
          {label}：
        </div>
        <div className="search-content">
          <div className="clearfix">
            <Button className="fl" onClick={() => this.showDeviceSelect(1)}>
              <IconFont type="icon-S_Photo_ListTree" />
              列表模式
            </Button>
            <Button className="fr" onClick={() => this.showDeviceSelect(2)}>
              <IconFont type="icon-S_Photo_ListMap" />
              地图模式
            </Button>
          </div>
          {!!selectList.length && (
            <div className="grap-point-content">
              <div className="camera-selected">
                <List data={selectList} rowHeight={24} key={this.state.keyMath} rowRenderer={this.rowRender} />
              </div>
              <div className="clear" onClick={this.clearDevices}>
                <IconFont type="icon-S_Edit_Delete" className="data-repository-icon" />
                清空摄像机
              </div>
            </div>
          )}
        </div>
        {modalType &&
          (modalType === 2 ? (
            <MapSelectModal
              title="地图选择"
              width={920}
              points={resourceData}
              className="point-select-map"
              visible={this.state.modalShow}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              selectList={selectList}
            />
          ) : (
            <WifiListSelectModal
              title="列表选择"
              visible={this.state.modalShow}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              resourceData={resourceData}
              WifiIds={WifiIds}
            />
          ))}
      </div>
    );
  }
}

export default GrapPoint;
