import React from 'react'
import './index.less'
const ColorSpan = () => {
  return (
    <div className="span-all">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
export default ColorSpan