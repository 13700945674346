import React, { Component } from 'react';
import { Select } from 'antd';
import ColorSpan from '../ColorSpan';
import './index.less';

const Option = Select.Option;
const IconFont = Loader.loadBaseComponent('IconFont');
const upperColorLocal = Dict.map.upperColorLocal;
const upperTexture = Dict.map.upperTexture;

const getOption = v => {
  if (v.text === '全部') {
    return <IconFont className="icon-all-color" type={'icon-S_AID_ClothesSolid'} />;
  } else if (v.text === '花色') {
    return <ColorSpan />;
  } else {
    return (
      <span
        className={`color-bg ${v.text === '白色' || v.text === '全部' ? 'bg-border-white' : ''}`}
        style={{
          backgroundColor: v.label,
          width: '14px',
          height: '14px',
          display: 'inline-block'
        }}
      />
    );
  }
};
const BodyCharacteristics = ({ iconFont, label, placeholder, change, nameUpper, valueUpper, activeTabId, nameStyle, valueStyle }) => {
  return (
    <div className="body-char-wrapper">
      <div className="search-title">
        {iconFont && <IconFont type={iconFont} />}
        {label}：
      </div>
      <div className="search-content" id={`a-${activeTabId}`}>
        <Select
          placeholder={placeholder}
          onChange={v => change({ [nameUpper]: v })}
          value={valueUpper}
          showSearch
          optionFilterProp="children"
          className="color-select fl"
          dropdownClassName="color-select-first-drop"
          getPopupContainer={() => document.getElementById(`a-${activeTabId}`)}
          suffixIcon={<IconFont type="icon-S_Arrow_BigDown" />}>
          {upperColorLocal.map(v => {
            return (
              <Option value={v.value} key={v.value}>
                {getOption(v)}
                {v.text}
              </Option>
            );
          })}
        </Select>
        <Select
          placeholder={placeholder}
          onChange={v => change({ [nameStyle]: v })}
          value={valueStyle}
          showSearch
          optionFilterProp="children"
          className="color-select fr"
          dropdownClassName="color-select-second-drop face-color-select"
          getPopupContainer={() => document.getElementById(`a-${activeTabId}`)}
          suffixIcon={<IconFont type="icon-S_Arrow_BigDown" />}>
          <Option value={null} key={null}>
            全部
          </Option>
          {upperTexture.map(v => {
            return (
              <Option value={v.value} key={v.value}>
                {v.label}
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default BodyCharacteristics;
