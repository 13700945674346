import React from 'react'
import './index.less'
import _ from 'lodash'
const ListComponent = Loader.loadComponent('ListComponent')
const ModalComponent = Loader.loadBaseComponent('ModalComponent')

class WifiListSelectModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      WifiIds: props.WifiIds || []
    }
  }

  /**
   * @desc 确认按钮
   */
  submitSelect = () => {
    this.props.onOk && this.props.onOk(this.selectList)
    return Promise.resolve()
  }

  /**
   * @desc 修改选中的wifi设备
   */
  changeSelect = ({ item, flag, changeAll, list }) => {
    if (flag) {
      !changeAll
        ? this.selectList.push(item)
        : (this.selectList = [].concat(this.selectList, list));
    } else {
      if (changeAll) {
        this.selectList = _.differenceBy(this.selectList, list, 'id');
      } else {
        _.remove(this.selectList, v => v.id === item.id);
      }
    }
    let WifiIds = []
    this.selectList.forEach(item => WifiIds.push(item.id))
    this.setState({
      WifiIds
    })
  }

  getSelectList = (WifiIds) => {
    let { resourceData } = this.props
    this.selectList = resourceData.filter(item => ~WifiIds.indexOf(item.id)) || []
    return this.selectList
  }
  render() {
    const { resourceData, visible, onCancel } = this.props
    const { WifiIds } = this.state
    let selectList = this.getSelectList(WifiIds)
    return (
      <ModalComponent
        title='列表选择'
        width={704}
        visible={visible}
        className='modal-wifi-select'
        onOk={this.submitSelect}
        onCancel={onCancel}
        disabled={selectList.length === 0}
      >
        <div className='wifi-select-box'>
          <ListComponent 
            hasTitle={true}
            checkable={true}
            hasSearch={true}
            hasCheckAll={true}
            listData={resourceData}
            selectList={selectList}
            onChange={this.changeSelect}
            className='wifi-resource-all'
            title='WIFI列表'
            inputPlaceholder='请输入你要搜索的WIFI'
          />
          <ListComponent 
            hasTitle={true}
            hasClear={true}
            listData={selectList}
            onChange={this.changeSelect}
            className='wifi-selected'
            title={`已选WIFI(${selectList.length || 0}个)`}
          />
        </div>
      </ModalComponent>
    )
  }
}

export default WifiListSelectModal