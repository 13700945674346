import React, { Component } from 'react'
import { Checkbox } from 'antd'
import { toJS } from 'mobx'
import './index.less'

const IconFont = Loader.loadBaseComponent('IconFont')
const CheckboxGroup = Checkbox.Group

function GroupExcludeCheckbox({ change, name, iconFont, data, label, value, excludes = [] }) {
  function onChange(codes) {
    change({ [name]: codes })
  }
  function computedExcludes(code, checkeds) {
    const exCodes = excludes.find(v => v.includes(code))

    if (checkeds && checkeds.length > 0 && exCodes && !checkeds.includes(code) && checkeds.filter(v => exCodes.includes(v)).length > 0) {
      return true
    }
    return false
  }
  return (
    <div className="check-group-exclude-wrapper">
      <div className="search-title">
        {iconFont && <IconFont type={iconFont} className="data-repository-icon" />}
        {label}：
        {value && value.length > 0 && (
          <span className="clear-btn" onClick={() => change({ [name]: [] })}>
            清空
          </span>
        )}
      </div>
      <div className="search-content">
        {data && (
          <CheckboxGroup value={toJS(value || [])} onChange={onChange} className="clearfix">
            {data.map(v => (
              <Checkbox value={v.value} key={v.value} disabled={computedExcludes(v.value, toJS(value))}>
                {v.label}
              </Checkbox>
            ))}
          </CheckboxGroup>
        )}
      </div>
    </div>
  )
}

export default GroupExcludeCheckbox
