import React from "react";
import { withRouter } from "react-router-dom";
import { Button, message } from "antd";
// import CaptureViewPlus from './ImageView/captureViewPlus.js';

import "./index.less";

const IconFont = Loader.loadBusinessComponent("IconFont");
const UploadSingleFile = Loader.loadBusinessComponent("UploadComponents", "UploadSingleFile");
const IconSpan = Loader.loadBaseComponent("IconSpan");
const Loading = Loader.Loading;

@withRouter
@Decorator.businessProvider("tab")
class SearchButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgCutVisible: false,
      loading: false
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ loading: false });
    }
  }
  /**
   * @desc 上传
   * rectType face 人脸 body人体 vehicle机动车 nonvehicles非机动车 结构化类型
   */
  onUpload = async url => {
    const { type = "face", handFrame } = this.props;
    if (!url) {
      return message.error("图片上传失败");
    }
    const URL = url.url;
    let list = [];
    let result;
    try {
      result = await Service[type === "nonVehicle" ? "vehicle" : type].getFeature({ url: URL });
    } catch (e) {
      this.setState({ loading: false });
      return message.warn("特征值提取失败！");
    }
    list = result.data.list || result.data.vehicles || result.data.nonvehicles;
    if (list.length === 0) {
      this.setState({ loading: false });
      return message.warn("特征值提取失败！");
    }
    const featureList = list.map(v => ({
      feature: v.feature,
      type: type,
      value: `${v.rect.left},${v.rect.top},${v.rect.width},${v.rect.height}`
    }));
    const options = { feature: featureList[0].feature };
    try {
      const miniImage = await Utils.getRectImage(URL, featureList[0].value, type);
      return handFrame(miniImage, featureList, featureList.length !== 1, featureList.length === 1 ? options : undefined, URL);
    } catch (e) {
      return handFrame(URL, featureList, featureList.length !== 1, featureList.length === 1 ? options : undefined, URL);
    } finally {
      this.setState({ loading: false });
    }
  };

  /**
   * @desc 默认显示组件
   */
  getUploadView = (url, loading) => {
    const { type } = this.props;
    return !!!url ? (
      <div className="resource-search-box-btn">
        {type === "vehicle" ? (
          <IconFont type="icon-M_Bar_PhotoSearch" className="vehicle-icon vehicle-icon-btn" />
        ) : (
          <Button className="search-btn" loading={loading}>
            {!loading && <IconFont type="icon-M_Bar_PhotoSearch" />}
            <span>以图搜图</span>
          </Button>
        )}
      </div>
    ) : (
      <div className="resource-search-box-btn">
        {type === "vehicle" && <IconFont type="icon-M_Bar_PhotoSearch" className="vehicle-icon vehicle-icon-search" />}
        <div className="img-view-box">
          <img src={url} />
          {loading && <Loading />}
        </div>
      </div>
    );
  };

  /**
   * @desc 显示隐藏截取图片
   */
  setImgCutVisible = () => {
    const { handFrame, url, frameUrl, rects } = this.props;
    handFrame(url, rects, true, undefined, frameUrl);
  };

  // /**
  //  * @desc 图片截图
  //  */
  // captureCb = url => {
  //   const { changeUrl } = this.props;
  //   changeUrl && changeUrl(url);
  //   if (url && url.indexOf('data:image/png;base64') >= 0) {
  //     url = url.split(',')[1];
  //   }
  //   this.setState({
  //     imgCutVisible: false
  //   });
  // };

  /**
   * @desc 删除图片
   */
  delPic = () => {
    this.setState(
      {
        imgCutVisible: false
      },
      () => {
        this.props.changeUrl && this.props.changeUrl("");
      }
    );
  };

  /**
   * @desc 上传图片
   */
  uploadService = async data => {
    await this.setState({ loading: true });
    return Service.face.uploadImg(data).catch(e => this.setState({ loading: false }));
  };

  render() {
    const { imgCutVisible, loading } = this.state;
    const { url, type } = this.props;
    let uploadBtn = this.getUploadView(url, loading);
    return (
      <div
        className={`baselib-upload-contaier ${type === "vehicle" && "vehicle-upload-btn"} ${!url &&
          "vehicle-upload-btn-has-url"}`}
      >
        <UploadSingleFile
          expiretype={2}
          uploadBtn={uploadBtn}
          uploadType="remote"
          uploadTip={false}
          uploadService={this.uploadService}
          uploadDone={this.onUpload}
          maxSize={5}
        />
        {url && (
          <div className="img-opare">
            <span>
              <IconSpan className="delete-img" icon="icon-S_Edit_Delete" mode="inline" label="删除图片" onClick={this.delPic} />
              <IconSpan
                mode="inline"
                icon={imgCutVisible ? "icon-S_Edit_Close" : "icon-S_View_ChooseRectangle"}
                label={imgCutVisible ? "取消框选" : "手动框选"}
                onClick={this.setImgCutVisible}
              />
            </span>
          </div>
        )}
        {/* {imgCutVisible && (
          <div className="img-cut-wrapper">
            <div className="img-cut-header">
              <span>手动框选</span>
              <IconSpan icon="icon-S_Edit_Close" mode="inline" onClick={this.setImgCutVisible} />
            </div>
            <CaptureViewPlus
              isDownload={true}
              isStorage={true}
              isRotate={true}
              isScan={true}
              isScale={true}
              styles={{ width: '280px' }}
              options={{
                capture: true,
                init: true,
                urlSrc: url,
                width: '400px',
                height: '400px',
                captureCb: this.captureCb
              }}
            />
            <div className="img-cut-footer" />
          </div>
        )} */}
      </div>
    );
  }
}

export default SearchButton;
