/**
 * @author wwj
 * @createTime 2019-1-7
 * @update wwj
 * @updateTime 2019-1-9
 */

import React from "react";
import { Tooltip, message } from "antd";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import "./index.less";

const ImageView = Loader.loadBaseComponent("ImageView");
const IconFont = Loader.loadBaseComponent("IconFont");
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");

@withRouter
@Decorator.businessProvider("tab")
class List extends React.Component {
  /**
   * @desc 获取关联人脸人体icon
   */
  getIconByType = () => {
    const { type = "face", data = {} } = this.props;
    let iconType = "",
      actionName;
    switch (type) {
      case "face":
        iconType = data.hasBody ? "icon-L_AID_Body" : null;
        actionName = "bodyLibrary";
        break;
      case "body":
        iconType = data.hasFace ? "icon-S_Bar_Face" : null;
        actionName = "faceLibrary";
        break;
      default:
        break;
    }
    return iconType ? (
      <AuthComponent actionName={actionName}>
        <IconFont className="relative-icon" type={iconType} />
      </AuthComponent>
    ) : null;
  };

  /**
   * @desc 跳转关联查询
   * @param {Number} mode 1: 关联人脸 2: 关联人体
   */
  goPage = async args => {
    const { tab, location, data, searchData = {} } = this.props;
    const { startTime, endTime, timerTabsActive } = searchData;
    const option = {
      startTime,
      endTime,
      timerTabsActive
    };
    const serarhParams = {
      id: data.id,
      isSearch: true,
      searchType: 0
    };
    const dbData = {
      id: data.id,
      url: data.faceUrl || data.bodyUrl,
      frameUrl: data.sceneUrl,
      imgId: data.imgId,
      smId: data.id,
      searchData: option,
      data
    };
    await LM_DB.add("parameter", dbData);
    tab.goPage({ moduleName: args.moduleName, location, data: serarhParams });
  };

  goPersonDetail = async () => {
    const { data, tab } = this.props;
    const moduleName = "objectMapPersonnelDetail";
    let personId;
    try {
      const result = await Service.person.queryPersonBindInfo({ aid: data.aid, personId: "" });
      personId = result.data.personId;
    } catch (e) {
      console.error(e);
      message.warn("获取人员ID失败！");
    }
    const id = personId ? personId : data.aid;

    tab.openDetailPage({
      moduleName,
      value: id,
      data: { id },
      beforeAction: async () => {
        const type = personId ? "ploy" : "aid";
        await window.LM_DB.add("parameter", { id, url: data.personInfoUrl, type });
      }
    });
  };
  /**
   * @desc 获取关联检索
   */
  getAssociationRetrieval = () => {
    const { data, type = "face", personMap = true, isNonMotorized } = this.props;
    const FaceIcon = (
      <AuthComponent actionName="faceLibrary">
        <Tooltip title={!!data.hasFace ? "关联人脸检索" : "人脸检索"} onClick={() => this.goPage({ moduleName: "faceLibrary" })}>
          <IconFont type="icon-S_Bar_Face" />
        </Tooltip>
      </AuthComponent>
    );
    const BodyIcon = (
      <AuthComponent actionName="bodyLibrary">
        <Tooltip title={!!data.hasBody ? "关联人体检索" : "人体检索"} onClick={() => this.goPage({ moduleName: "bodyLibrary" })}>
          <IconFont type="icon-L_AID_Body" />
        </Tooltip>
      </AuthComponent>
    );
    const CarIcon = (
      <AuthComponent actionName={isNonMotorized ? "nonVehicleLibrary" : "vehicleLibrary"}>
        <Tooltip
          title="车辆检索"
          onClick={() => this.goPage({ moduleName: isNonMotorized ? "nonVehicleLibrary" : "vehicleLibrary" })}
        >
          <IconFont type={isNonMotorized ? "icon-S_Bar_NonMotor" : "icon-S_Bar_Motor"} />
        </Tooltip>
      </AuthComponent>
    );
    const PersonIcon = (
      <AuthComponent actionName="objectMapPersonnel">
        <Tooltip title="人员档案" onClick={this.goPersonDetail}>
          <IconFont type="icon-S_Bar_NameCard" />
        </Tooltip>
      </AuthComponent>
    );
    let templete = [];
    switch (type) {
      case "face":
        templete.push(FaceIcon); // 人脸检索
        !!data.hasBody && templete.push(BodyIcon); // 关联人体检索
        break;
      case "body":
        templete.push(BodyIcon); // 人体检索
        !!data.hasFace && templete.push(FaceIcon); // 关联人脸检索
        break;
      case "vehicle":
        templete.push(CarIcon);
        break;
      default:
        break;
    }
    if (personMap && data.aid && data.aid !== "0") {
      templete.push(PersonIcon);
    }
    return templete ? <div className="search-btn-group">{templete}</div> : null;
  };

  /**
   * @desc 获取显示内容
   */
  getFooterComponent = () => {
    const { data, type = "face", isNonMotorized, hidePlante, accessType, entry } = this.props;
    let score = data.score;
    if (score) {
      if (score >= 100) {
        score = 99;
      } else {
        score = score.toString().substring(0, 2);
      }
    }
    return (
      <div className="item-info">
        {!hidePlante && type === "vehicle" && !isNonMotorized && (
          <div className="item plate-number">
            <IconFont type="icon-S_Bar_Brand" />
            {data.plateNo || "-"}
          </div>
        )}
        {!!data.score && (
          <div className="item">
            <IconFont type="icon-S_Photo_Like" />
            <span className="info-value">
              相似度
              <span className="slide-box">
                <span style={{ width: score + "%" }} />
              </span>
              <span className="highlight">{score}%</span>
            </span>
          </div>
        )}
        <div className="item" title={data.deviceName}>
          <IconFont type="icon-S_Bar_Add" />
          {data.deviceName}
        </div>
        <div className="item capture-time">
          <IconFont type="icon-S_Edit_ClockEnd" />
          {Utils.formatTimeStamp(data.captureTime)}
        </div>
        {type === "vehicle" && entry === "records" && (
          <div className="item">
            {accessType && <IconFont type={accessType === "117052" ? "icon-S_Allow_CarIn" : "icon-S_Allow_AllocationIn"} />}
          </div>
        )}
        {this.getAssociationRetrieval()}
      </div>
    );
  };

  /**
   * @desc 获取图片Url
   */
  getUrlPath = () => {
    const { type = "face", data } = this.props;
    return data[`${type}Url`];
  };

  /**
   * @desc 查看详情
   */
  goDetail = () => {
    let {
      tab,
      detailModuleName = "faceLibraryDetail",
      data,
      searchData,
      type,
      hasDetail = true,
      goPage,
      isNonMotorized,
      goSnapshotDetail
    } = this.props;
    if (goSnapshotDetail) {
      goSnapshotDetail(data, type);
      return;
    }
    if (!hasDetail) {
      return;
    }
    if (detailModuleName === "resourceSearchDetail") {
      return goPage(data.id);
    }
    const id = data.id.toString();
    tab.openDetailPage({
      moduleName: detailModuleName,
      value: id,
      data: { id },
      beforeAction: async () => {
        await window.LM_DB.add("parameter", {
          id,
          data,
          searchData: _.cloneDeep({
            ...Utils[`${isNonMotorized ? "nonVehicle" : type}Options`](searchData),
            timerTabsActive: searchData.timerTabsActive
          })
        });
      }
    });
  };

  render() {
    const { size = "normal", type = "face", data, checkboxItem = null, children } = this.props;
    return (
      <div className={`baselib-list-item size-${size} type-${type}`}>
        <div className="item-img-box img-hover-scale" onClick={this.goDetail}>
          <ImageView src={type === "accessControl" ? data.pictureUrl : data[`${type}Url`]} />
          {this.getIconByType()}
          {checkboxItem}
        </div>
        {this.getFooterComponent()}
        {children}
      </div>
    );
  }
}

export default List;
