import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SelectIMGPicture from "../SelectIMGPicture";
import "./index.less";

const ModalComponent = Loader.loadBaseComponent("ModalComponent");
// const SelectIMGPicture = Loader.loadBusinessComponent('BaseLibComponents', 'SelectIMGPicture');

@withRouter
@Decorator.businessProvider("tab")
class SearchMapModal extends Component {
  onClickSelectRect = imagePath => {
    const { tab, type = "face", onCancel } = this.props;
    const id = Utils.uuid();
    onCancel && onCancel();
    LM_DB.add("parameter", {
      id,
      url: imagePath,
      frameUrl: imagePath
    }).then(() => {
      tab.goPage({
        moduleName: `${type}Library`,
        action: "replace",
        data: {
          id,
          searchType: 1,
          isSearch: true
        }
      });
    });
  };

  render() {
    const { url, rects, onClickDefaultRect } = this.props;
    return (
      <ModalComponent {...this.props} className="search-map-modal">
        <SelectIMGPicture
          lazyLoad={true}
          src={url}
          rects={rects}
          onClickDefaultRect={onClickDefaultRect}
          onClickSelectRect={this.onClickSelectRect}
        />
      </ModalComponent>
    );
  }
}

export default SearchMapModal;
